import React from 'react';
import Navbar from './components/Navbar';
 import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './components/pages/Home';
import Services from './components/pages/Services';
import Produits from './components/pages/Produits';
import Signup from './components/pages/Signup';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
   
          <Route path='/' exact Component={Home} />
          <Route path='/services' exact Component={Services} />
          <Route path='/produits' exact Component={Produits} />
          <Route path='/sign-up' exact Component={Signup} />
         
        </Routes>
      </Router>
    </>
  );
}

export default App;
