import React from 'react'
import '../../App.css';


function Services() {
  return (
    <h1 className='services'>Services</h1>

  )
}

export default Services
