import React from 'react'
import '../../App.css';

function Produits() {
  return (
     <h1 className='produits'>Products</h1>
  )
}

export default Produits
