import React from 'react'
import '../../App.css';

function Signup() {
  return (
    <h1 className='sign-up'>Sign Up</h1>

  )
}

export default Signup
