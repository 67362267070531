import React from 'react'
import CardItem from './CardItem'
import './Cards.css'
 

function Cards() {
  return (
    <div className='cards'>
      <h1>WHO WE ARE ?</h1>
       <div className='cards__container'>
        <div className='cards__wrapper'>
            <ul className='cards__items'>
             <CardItem  src="images/logo.jpg" 
             text="We are an IT company based in Kenitra, Morocco. Our mission is to develop software for the Moroccan entrepreneurial market and small businesses."
              label="About Us"
             path=""/> 
              <CardItem  src="images/img-11.png" 
             text={
              <span>
              Marokko Biz It Development are part of Marokko Biz of 31.01.12 S.A.R.L. <br />
              The purpose of Marokko Biz It Development is to develop software for the Moroccan business community.
              <br /><br />
              Our vision is to help Morocco be digitized.
            </span> }
             label="Objectives"
             path=""/>   
            </ul>
        </div>
       </div>
       <h1>WHY TO CHOOSE US?</h1>
       <div className='cards__container'>
        <div className='cards__wrapper'>
            <ul className='cards__items'>
              <CardItem  src="images/solu.jpeg"  text={<span>All our solutions and applications, benefit from a modern design.<br /><br /> Based on the latest technologies.</span> }/>
              <CardItem  src="images/teams.png"  text={<span>Thanks to the teams dedicated to each project, The teams is always dedicated to each project.<br /><br />The delivery time is very reduced delivery time is very reduced.</span> } />
              <CardItem  src="images/teamsupport.jpg"  text={<span>Our team not only supports you in the development, and implementation of your solution.<br /><br />Also support your solutions after.</span> } />
                
            </ul>
        </div>
       </div>
    </div>
  )
}

export default Cards
