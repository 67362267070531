import React from 'react'; 
import { Button } from './Button';
import './HeroSection.css';
import '../App.css';

function HeroSection() {
  return (
    <><div className='hero-container'>
          <video src='/videos/video-4.mp4' autoPlay loop muted />
          <h1>Your digitalisation partner in Morocco</h1><p> We help you succeed in your digital transformation.</p>
       <div className='hero-btns'>
              
     {/*
      <Button 
      className='btns' 
      buttonStyle='btn--outline'
       buttonSize='btn--large'>GET STARTED</Button>
     <Button 
     className='btns' 
     buttonStyle='btn--primary' 
     buttonSize='btn--large'> WATCH TRAILER
     <i className='far fa-play-circle' />
     </Button>
    */}
          </div>
      </div>
    
         
          </>

  )
}

export default HeroSection
