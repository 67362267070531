import React from 'react'
import { Button } from './Button'
import './Footer.css'
import { Link } from 'react-router-dom'


function Footer() {
  return (
    <div className='footer-container'>
    
    <div className='footer-links'>
        <div className='footer-link-wrapper'>
            {/* <div className='footer-link-items'>
                <h2>About Us</h2>
                {/* <Link to='/sign-up'>How it's work</Link>
                <Link to='/'>Testimonials</Link>
                <Link to='/'>Careers</Link>
                <Link to='/'>Investors</Link>
                <Link to='/'>Terms of Services</Link>}

            </div> */}
   <div className='footer-link-items'>
  <h2>CONTACT US</h2>
  <h3><i className="fa-solid fa-phone"></i> Phone Number: +45 88 91 75 65</h3>
  <h3><i className="fa-regular fa-envelope"></i> Email: <a href="mailto:info@marokkobizit.com">info@marokkobizit.com</a></h3>
  <h3><i className="fa-solid fa-location-dot"></i> Lot 9 Rue, 10 Dziri V Montagne 90000 Tanger</h3>
</div>

        </div>
    </div>
    <div className='footer-links'>
        <div className='footer-link-wrapper'>
            {/* <div className='footer-link-items'>
                <h2>Videos</h2>
                {/* <Link to='/'>Submit ideo</Link>
                <Link to='/'>Ambassadors</Link>
                <Link to='/'>Agency</Link>
                <Link to='/'>Influencer</Link>
  }
            </div> */}
        
        </div>
    </div>
    <section className='social-media'>
        <div className='social-media-wrap'>
            <div className='footer-logo'>
                <Link to='/' className='social-logo'>
                    Marokko Biz It Development
                    <i class="fa-solid fa-code"></i>                    
                </Link>
            </div>
            <small className='website-rights'> Marokko Biz It 2022</small>
            <div className='social-icons'>
                <Link className='social-icon-link facebook'
                to='https://www.facebook.com/MarokkobizIT?_rdc=1&_rdr'
                target='_blank'
                aria-label='facebook'>
                    <i className='fab fa-facebook-f' />
                </Link>
                <Link className='social-icon-link instagram'
                to='/'
                target='_blank'
                aria-label='instagram'>
                    <i className='fab fa-instagram' />
                </Link>
                <Link className='social-icon-link linkdin'
                to='/'
                target='_blank'
                aria-label='linkdin'>
                    <i  className='fab fa-linkdin' />
                </Link>
            </div>
        </div>
    </section>
    </div>
  )
}

export default Footer
