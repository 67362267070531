import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome CSS
import { Button } from './Button';





  function Navbar() {
    const [click, setClick] = useState(false); 
    const [button, setButton] = useState(true); 
    
    const handleClick = () => {
      setClick(!click); 
     }
    
    const closeMobileMenu = () => setClick(false);
    
    const showButton = () => {
      if(window.innerWidth <=960 ) {
        setButton(false); 
      }
      else
      {
        setButton(true);
      }
    }; 

    window.addEventListener('resize',showButton);


    useEffect(() => {
      showButton();
    },[]);


    return (
    <>
    <nav className='navbar'>
     <div className='navbar-container'>
    <Link to="/" className='navbar-logo'  onClick={closeMobileMenu}>
        Marokko Biz It <i class="fa-solid fa-code"> </i></Link>
    <div className='menu-icon' onClick={handleClick} >
    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
    </div>
    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
      <li className='nav-item'>
        <Link to='/' className='nav-links' onClick={closeMobileMenu}>
          Home
        </Link>
      </li>
      {/*

      <li className='nav-item'>
        <Link to='/services' className='nav-links' onClick={closeMobileMenu}>
          Services
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/produits' className='nav-links' onClick={closeMobileMenu}>
          Products
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/sign-up' className='nav-links-mobile' onClick={closeMobileMenu}>
          Sign up
        </Link>
        {button && <Button buttonStyle='btn--outline'>SIGN UP</Button>}

      </li>
      */}
    </ul>

    </div>

   </nav>
    </>  
  );

}

export default Navbar;
